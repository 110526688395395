/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Internal dependencies
 */
import Blockquote from '../components/blockquote';
import ClientList from '../components/client-list';
import Form from '../components/form';
import Hero from '../components/hero';
import Layout from '../components/layout';
import Section from '../components/section';
import SEO from '../components/seo';
import ButtonGroup from '../components/button-group';
import Button from '../components/button';

const Web3ConsultantUKPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={ location }
            hideHeader
        >
            <SEO
                title="web3 Consultant UK"
                description="I educate companies on web3 and explain what's possible with blockchain technology for businesses, brands and individuals."
            />
            <Hero
                imageName="tom-hirst-headshot.png"
                imageAlt="Tom Hirst is a technical web3 consultant"
                imageCaption="Tom Hirst is a technical web3 consultant."
                noHeader
                standardHeight
            >
                <h1>web3 Consultant UK</h1>
                <p className="lead">I&apos;m a web3 consultant in the UK who educates businesses, brands and individuals about the possibilities of blockchain technology.</p>
                <p>Are you looking for:</p>
                <ul className="check-list">
                    <li>
                        <FontAwesomeIcon icon="check" />
                        Someone to explain how your business can leverage web3?
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        A go-to-market strategy for your upcoming web3 project?
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        Someone with the connections to plan, build and launch an web3 project with you?
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        An audit of your existing web3 project to identify problems and fix them?
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        A second opinion on the technical aspects of your web3 or blockchain project?
                    </li>
                </ul>
                <p>
                    Where ever you are in the world, I can help.
                </p>
                <ButtonGroup>
                    <Button to="#hire-me" icon="arrow-right" text="Hire me today" color="green" anchor />
                    <Button to="#read-more" icon="arrow-down" text="Read more about me" anchor />
                </ButtonGroup>
            </Hero>
            <Section narrow>
                <h2 id="read-more">What can web3 do for my business?</h2>
                <p>web3 is the next phase of the internet powered by blockchain technology. While the technology is fledgling, there web3 can help drive revenue for your business and deepen connections with your fans, today.</p>
                <p>You now have new forms value creation at your disposal with web3. Imagine being able to reward your loyal customers with desirable digital products or giving them a stake in your endeavour itself. The token economy of web3 puts exciting new possibilities at the your business' fingertips.</p>
                <p>As cryptocurrencies, blockchains and web3 go mainstream, you may be leaving money on the table if you don&apos;t carefully consider the benefits.</p>
                <p>If you want to:</p>
                <ul>
                    <li>Get ahead of your competition by adopting web3 early</li>
                    <li>Experiment with digital asset projects at any scale</li>
                    <li>Provide additional value for your most loyal fans</li>
                    <li>Add a web3 based revenue stream to your business</li>
                    <li>Build customer affinity using tokens</li>
                </ul>
                <p>I can tell you exactly what you need to do to start introducing your business to web3, today.</p>
                <h2>Why is Tom Hirst a reliable web3 consultant?</h2>
                <p>The web3 space is new. There aren&apos;t many experts around. So what makes Tom Hirst a reliable partner to consult with?</p>
                <p>As a long-time full-stack software engineer, I&apos;ve been delivering digital projects for over a decade. After a successful time in web2, in 2021 I went all in on web3.</p>
                <p>I&apos;ve submerged myself in web3 technology, news and culture since and have contributed to projects ranging from NFT launches to DAO initiatives to DeFi protocol engineering since.</p>
                <p>My process is strategic. I&apos;m a clear thinker who absorbs the sector and translates that information into actionable advice for my clients.</p>
                <p>Alongside my strategic work, I&apos;m an active member of many prominent NFT communities and DAOs in the space. I&apos;m an active collector of NFT art and I&apos;m a member of influential communities and DAOs within the space. This gives me a rolodex of web3 contacts we can call on to further assist with your project if required.</p>
                <p>To summarise, I know what makes a good web3 project and I can help you make yours one too. Here are some links that you might find interesting for further proof:</p>
                <ul>
                    <li>
                        <a href="https://www.tomhirst.com/getting-started-with-nfts">If you want a detailed breakdown of non-fungible tokens, here&apos;s an article I wrote on that</a>
                    </li>
                    <li>
                        <a href="https://shiny.mirror.xyz/OUampBbIz9ebEicfGnQf5At_ReMHlZy0tB4glb9xQ0E">Here&apos;s an article I co-authored on ERC721 smart contract gas optimisation</a>
                    </li>
                    <li>
                        <a href="https://github.com/tomhirst">Check out the web3 code I&apos;ve written on GitHub</a>
                    </li>
                    <li>
                        <a href="https://opensea.io/tom_hirst_vault">View some of the NFTs that I own on OpenSea</a>
                    </li>
                </ul>
                <h2>What are some example web3 projects my business could run?</h2>
                <p>I can help you plan, create and deliver the following:</p>
                <ul>
                    <li>
                        A PFP (profile picture) project of 5,000 randomly generated avatars for your fans to use on social media
                    </li>
                    <li>
                        A collection of art pieces with significance to your business' story
                    </li>
                    <li>
                        Digitally ticketed access to an event your business plans to run soon
                    </li>
                    <li>
                        A loyalty programme where tokens are distributed to members as rewards
                    </li>
                    <li>
                        A community that requires ownership of a branded token for access
                    </li>
                </ul>
                <p>If any of these suggestions sound useful, or you have a specific idea, <a href="#hire-me">get in touch</a> so we can discuss together.</p>
                <h2 id="hire-me">Ready make your project a success with a dedicated web3 consultant?</h2>
                <p>
                    I&apos;d love to consult with you on your web3 project.
                    Fill out the form with a few details and I&apos;ll get back to you with some initial advice for free:
                </p>
                <Form
                    id="contact-form"
                    contact
                    buttonAsideText="I'll reply as soon as I can!"
                    messagePlaceholder="Hi Tom, my business has a web3 project that needs a set of expert eyes."
                    pathname={ location && location.pathname }
                    budgetLabel=" "
                />
                <h2>Helping businesses succeed on the internet since 2009</h2>
                <p>I&apos;ve worked on projects for some great names with great people:</p>
                <ClientList />
                <h2>At the cutting edge, yet down to earth</h2>
                <p>My clients trust my expertise and find me easy to work with:</p>
                <div className="blockquote-list-with-middle">
                    <Blockquote
                        quote="We have appreciated his technical advice. I can really recommend working with Tom."
                        cite="Henning Hovland, CTO at Dekode"
                        imageName="hh-headshot.jpg"
                        imageAlt="Henning Hovland"
                        mediumQuote
                        boxed
                    />
                    <Blockquote
                        quote="Tom is structured, accountable, timely, stable and easy to talk to. I would recommend working with him to anyone."
                        cite="Hillevi Røstad, CAO at Dekode"
                        imageName="hr-headshot.jpg"
                        imageAlt="Hillevi Røstad"
                        boxed
                    />
                </div>
                <h2>Ready to start moving your web3 project forward with a consultation?</h2>
                <p>We can have a conversation <strong>today</strong>.</p>
                <a className="button is-green has-icon" href="#hire-me">
                    Let&apos;s talk web3
                    <FontAwesomeIcon icon="arrow-up" />
                </a>
            </Section>
        </Layout>
    );
};

Web3ConsultantUKPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

Web3ConsultantUKPage.defaultProps = {
    location: {},
};

export default Web3ConsultantUKPage;
